<script>
export default {
    props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sv: [
        { title: 'Vad är det för garanti?', 
        stikkanContent: 'Kommer med 10 års garanti.', 
        copyContent: 'Ingen garanti.', 
        show: true },
        { title: 'Är det orginal?', 
        stikkanContent: 'Ja, orginalet sedan 1982.', 
        copyContent: 'Nej, ej orginal.', 
        show: false },
        { title: 'Vad är det för material?', 
        stikkanContent: 'Elzinkad, rostar ej.', 
        copyContent: 'Målad vilket rostar.', 
        show: false },
        { title: 'Var är den tillverkad?', 
        stikkanContent: 'Tillverkad i Sverige.', 
        copyContent: 'Troligen Kina.', 
        show: false },
        { title: 'Hur kommer den förpackad?', 
        stikkanContent: 'I en fin presentkartong.', 
        copyContent: 'Enkel papplåda.', 
        show: false },
        { title: 'Vad kostar frakt?', 
        stikkanContent: 'Inga extra kostnader tillkommer.', 
        copyContent: 'Tull och moms kan tillkomma.', 
        show: false },
        { title: 'Hur funkar skatten?', 
        stikkanContent: 'Stikkan är svensk skattebetalare.', 
        copyContent: 'Vid köp av kopia vet man inte vem som får skatten.', 
        show: false },
        // Add more items as needed
      ],
      en: [
        { title: 'What is the terms of warranty?', 
        stikkanContent: 'Comes with 10 years warrany.', 
        copyContent: 'No warranty.', 
        show: true },
        { title: 'Is it original?', 
        stikkanContent: 'Yes, Original since 1982.', 
        copyContent: 'No, not original', 
        show: false },
        { title: 'What kind of material is it?', 
        stikkanContent: 'Zinc electroplated, not rusting. ', 
        copyContent: 'Painted and will rust.', 
        show: false },
        { title: 'Where is it made?', 
        stikkanContent: 'Made in sweden.', 
        copyContent: 'Most likely China.', 
        show: false },
        { title: 'How will it be delivered?', 
        stikkanContent: 'In a nice gift box.', 
        copyContent: 'Simple karton box.', 
        show: false },
        { title: 'How much is shipping?', 
        stikkanContent: 'No extra charges.', 
        copyContent: 'Duty and VAT may apply.', 
        show: false },
        { title: 'How does taxes work?', 
        stikkanContent: 'Stikkan is a swedish tax payer.', 
        copyContent: 'You dont know who gets the tax when buying a copy.', 
        show: false },
        // Add more items as needed
      ]
    };
  },
  computed: {
    items() {
      return this[this.selectedLanguage] || [];
    },
  },
  methods: {
    toggle(index) {
      this.items[index].show = !this.items[index].show;
    },
  },
};
</script>


<template>
    <div id="accordion">
      <div class="card" v-for="(item, index) in items" :key="index">
        <div class="card-header" v-bind:class="{ show: item.show }">
          <div class="btn btn-link" v-on:click="toggle(index)">
            <h2>{{ item.title }}</h2>
            <svg v-if="item.show" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"/></svg>
        </div>
        </div>
        <div class="card-body" v-on:click="toggle(index)" v-if="item.show">
            <ul>
                <li>
                    <h3>Stikkan</h3>
                    <p>{{ item.stikkanContent }}</p></li>
                <li>
                    <h3 v-if="selectedLanguage === 'sv'">Kopia</h3>
                    <h3 v-else>Copy</h3>
                    <p>{{ item.copyContent }}</p></li>
            </ul>

        </div>
      </div>
    </div>
  </template>

  
  <style scoped>
    #accordion {
        width: 50%;
        @media screen and (max-width: 900px) {
            width: 100%;
        }
    }
    .card {
        margin-bottom: 1rem;
        &:hover{
            cursor: pointer;
        }

    .card-header {
        background-color: #f8f9fa;
        border-radius: 1rem;
        &.show{
            border-radius: 1rem 1rem 0 0;
        }
        h2{
            margin: 0;
        }

        .btn-link{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            border-radius: 1rem;
        }
    }
    .card-body {
        padding: 1rem;
        background-color: #f8f9fa;
        border-radius: 0 0 1rem 1rem;
        h3{
            margin: 0;
        }
        ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        p{
            margin: 0;
        }
        >li:first-child{
            padding-bottom: 1rem;

        }
    }
  }
}
  </style>