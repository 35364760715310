<script>

export default {
  props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
  },
  methods: {
    navigateToWebsite() {
      window.location.href = 'https://stikkan.com/';
    },
    navigateToEngWebsite() {
      window.location.href = 'https://stikkan.com/en';
    },
  },
};
</script>

<template>
  <div v-if="selectedLanguage === 'sv'" id="presentation">
    <div>
      <H1>Stikkan Q&A</H1>
      <p>Den här texten och sidan har skrivits av
      <a href="https://stikkan.com/" target="_blank">
        STIKKAN.COM
      </a>
      för att ni som potentiell köpare av en vedspäntare skall ha så mycket information som möjligt.</p>
      <p>Jämför originalet med produkter som offereras på andra sidor.</p>
      <p>Bra kvalitet kommer man ihåg längre än priset.</p>
      <button @click="navigateToWebsite">Köp STIKKAN® originalet</button>
    </div>
  </div>
  <div v-else id="presentation">
    <div>
      <H1>Stikkan Q&A</H1>
      <p>This page has been written by
      <a href="https://stikkan.com/en" target="_blank">
        STIKKAN.COM
      </a>
      so you as a potential customer can compare the following with products offered on other sites.</p>
      <p>Compare the original with products that is offered on other sites.</p>
      <p>Good quality is remembered longer than the price.</p>
      <button @click="navigateToEngWebsite">Buy STIKKAN® the original</button>
    </div>
  </div>
</template>

<style scoped>
  #presentation {
    padding-right: 2rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    >div{
      background-color: #f8f9fa;
      border-radius: 1rem;
      padding: 1rem
    }
    @media screen and (max-width: 900px) {
      width: 100%;
      padding-bottom: 1rem
    }
    h1{
      margin: 0;
      padding-bottom: 2rem;
    }
    p{
      font-size: 1.2rem;
      margin: 0;
      padding-bottom: 0.2rem;
    }
    button{
      margin: 2rem 0;
      align-self: flex-start;
      
    }
  }
</style>
