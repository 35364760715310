<script>
import Accordion from './Accordion.vue';
import Presentation from './Presentation.vue';

export default {
  components: {
    Accordion,
    Presentation
  },
  data() {
    return {
      selectedLanguage: 'sv',
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Svenska', code: 'sv' },
        // Add more languages as needed
      ],
    };
  },
};
</script>

<template>
    <div id="main">
      <select v-model="selectedLanguage" id="language">
        <option v-for="(language, index) in languages" :key="index" :value="language.code">
          {{ language.name }}
        </option>
      </select>
      <div id="layout">
      <Presentation :selectedLanguage="selectedLanguage"/>
      <Accordion :selectedLanguage="selectedLanguage"/>
    </div>
    </div>
  </template>

  <style scoped>
    #main {
        max-width: 1280px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 2rem;
    }
    #language {
       padding: 5px;
    }
    #layout {
      max-width: 1280px;
      margin: 0 auto;
      padding: 2rem 0;
      display: flex;
      @media screen and (max-width: 900px) {
          flex-direction: column;
      }
    }
  </style>